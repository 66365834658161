import React, { useState, useCallback, useEffect } from "react";
import apiRequest from "../../functions/api";
import {
  MenuItem,
  Avatar,
  Typography,
  Button,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Briefkopf } from "../../components/Rahmendaten/Briefkopf";
import SendIcon from "@mui/icons-material/Send";

function EcSpeech(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [reactnummer] = useState("847");
  const { accessToken, hsutkey } = props;
  const [antworten, setAntworten] = useState({});
  const [fehlerliste, setFehlerliste] = useState([]);
  const [sprache, setSprache] = useState("");
  const [dialogWahl, setDialogWahl] = useState("");
  const [kursdaten, setKursdaten] = useState([]);
  const [textvalue, setTextvalue] = useState("");
  const [foreinLan, setForeinLan] = useState("");
  const [darstellung, setDarstellung] = useState(false);

  const setzeAntworten = (key, value) => {
    console.log("key: ", key);
    console.log("value: ", value);
    if (key === "fremdsprache") {
      setSprache(value);
    }
    if (key === "dialogwahl") {
      setDialogWahl(value);
    }
    let values = Object.assign(antworten);
    values[key] = value;
    setAntworten(values);
    forceUpdate();
  };

  useEffect(() => {
    if (sprache !== "" && dialogWahl !== "") {
      setDarstellung(false);
      loadDialog();
    }
  }, [dialogWahl]);

  const loadDialog = async () => {
    const headers = {
      Authorization: "Bearer " + accessToken,
    };
    const payload = {
      kennung: "sprachkurse",
      merkmal: "Sprachen",
      daten: antworten,
      reactnummer: reactnummer,
      fremdsprache: sprache,
      dialogWahl: dialogWahl,
      hsutkey: hsutkey,
    };
    apiRequest("POST", "sprachkurse", payload, headers, kurseCb, setRedirect);
  };

  const kurseCb = (response) => {
    setForeinLan("");
    if (response.status === 200) {
      let arrayNr = 0;
      if (response.data.arrayNr !== undefined) {
        arrayNr = response.data.arrayNr;
      }

      if (arrayNr !== 0) {
        setForeinLan(response.data.kursdaten[arrayNr].antwortFS);
      }
      setTextvalue("");
      setKursdaten(response.data?.kursdaten);
      setDarstellung(true);
    } else {
      if (response) {
        props.showError(response.data.message);
      } else {
        props.showError("Keine Internetverbindung");
      }
    }
  };

  const btnClick = (todo, eventkey, kennung) => {
    console.log("btnClick -todo: ", todo);
    console.log("btnClick -eventkey: ", eventkey);
    console.log("btnClick -kennung: ", kennung);
    console.log("btnClick -kursdaten: ", kursdaten);
    console.log("btnClick -foreinLan: ", foreinLan);
    console.log("btnClick -textvalue: ", textvalue);

    const headers = {
      Authorization: "Bearer " + accessToken,
    };
    const payload = {
      todo: todo,
      eventkey: eventkey,
      inhalt: kursdaten,
      kennung: kennung,
      eingabeText: textvalue,
      sendText: foreinLan,
    };
    apiRequest("POST", "sprachkurse", payload, headers, kurseCb, setRedirect);
  };

  const setRedirect = (path) => {
    navigate(path);
  };

  const handleEingaben = (event) => {
    setTextvalue(event.target.value);
  };

  const handleForainEingaben = (event) => {
    console.log("handleForainEingaben: ", event.target.value);
    setForeinLan(event.target.value);
  };

  const erklaerungAnzeigen = (text) => {
    const newText = text.split("\n").map((str) => <p>{str}</p>);
    return newText;
  };

  return (
    <div>
      <Briefkopf
        ueberschrift1="Sprache einfach lernen"
        ueberschrift2="Starte am besten gleich einen Dialog in der Fremdsprache, welche du lernen möchtest."
      />
      <div>
        <TextField
          select // durch select wird DropDown initialisiert
          key="fremdsprache"
          style={{}}
          label="Fremdsprache auswählen"
          fullWidth
          error={fehlerliste["fremdsprache"]}
          value={antworten["fremdsprache"] || ""}
          onChange={(event) => setzeAntworten("fremdsprache", event.target.value)}
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "#1fbba6" },
          }}
        >
          <MenuItem value={"englisch"}>Englisch</MenuItem>
        </TextField>
      </div>

      <div>
        <TextField
          select // durch select wird DropDown initialisiert
          key="dialogwahl"
          style={{ marginBottom: "45" }}
          label="Bitte Dialogart auswählen"
          fullWidth
          error={fehlerliste["dialogwahl"]}
          value={antworten["dialogwahl"] || ""}
          onChange={(event) => setzeAntworten("dialogwahl", event.target.value)}
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: { color: "#1fbba6" },
          }}
        >
          <MenuItem value={"restaurant"}>Restaurantbesuch</MenuItem>
          <MenuItem value={"smalltalk"}>Small Talk</MenuItem>
          <MenuItem value={"wegFragen"}>Nach dem Weg fragen</MenuItem>
          <MenuItem value={"Kontrolle"}>In eine Kontrolle geraten</MenuItem>
        </TextField>
      </div>

      {/* original code anfang */}

      {darstellung && (
        <>
          {kursdaten?.map((detail, index) => (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 0.5fr 1fr",
                gap: "5px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  border: "1px solid #ccc",
                  // backgroundColor: "#f0f0f0",
                }}
              >
                {detail.seite === "links" && (
                  <>
                    {detail.art === "textarea" && (
                      <>
                        <div>
                          <TextField
                            key={"communicator" + detail.key}
                            label="Speaker"
                            multiline
                            minRows={4}
                            variant="outlined"
                            disabled={detail.disabled}
                            value={detail.text}
                            style={{ width: "100%" }}
                          />
                          <div></div> {/* Zeilenumbruch */}
                          <Button
                            style={{ marginTop: "6px", marginRight: "20px" }}
                            disabled={detail.folge}
                            key={detail.key + "_btn_links_uebersetzen"}
                            onClick={() => btnClick("uebersetzen", detail.key, detail.kennung)}
                            variant="outlined"
                          >
                            "Übersetzen"
                          </Button>
                          <Button
                            style={{ marginTop: "6px" }}
                            key={detail.key + "_btn_links_vorlesen"}
                            onClick={() => btnClick("vorlesen", detail.key, detail.kennung)}
                            variant="contained"
                          >
                            vorlesen
                          </Button>
                          {detail.folge === true && (
                            <TextField
                              key={"uebersetzung" + detail.key}
                              minRows={4}
                              disabled={true}
                              name={detail.folgekey}
                              value={detail.uebersetzung}
                              style={{ width: "100%" }}
                              label="Sprecher"
                              multiline
                              defaultValue="Default Value"
                              variant="outlined"
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div
                style={{
                  //  padding: "20px",
                  border: "1px solid #ccc",
                }}
              ></div>
              <div
                style={{
                  //  padding: "20px",
                  border: "1px solid #ccc",
                }}
              >
                {detail.seite === "rechts" && (
                  <>
                    {detail.art === "antwort" && (
                      //               <div className="border p-4 rounded">
                      <div>
                        {detail.kennung === "answerMS" && (
                          <>
                            {detail.erklärung !== "" && erklaerungAnzeigen(detail.erklärung)}
                            {detail.erklärung === "" && (
                              <p>What would you like to answer? (Was möchten Sie antworten?) </p>
                            )}

                            <TextField
                              style={{ width: "100%", marginBottom: "10px" }}
                              label="deutschen Text eingeben"
                              key={"MS_" & detail.key}
                              onChange={handleEingaben}
                              multiline
                              minRows={4}
                              variant="outlined"
                              disabled={detail.disabled}
                              value={textvalue ? textvalue : detail.antwortMS}
                            />
                            <div></div>
                            {detail.disabled !== true && (
                              <Button
                                style={{ marginBottom: "10px" }}
                                key={detail.key + "btn_rechts_uebersetzen"}
                                onClick={() => btnClick("uebersetzen", detail.key, detail.kennung)}
                                variant="outlined"
                              >
                                übersetzen
                              </Button>
                            )}
                            <div></div>
                          </>
                        )}

                        {detail.folge === true && (
                          <>
                            <TextField
                              style={{ width: "100%" }}
                              label="englischen Text eingeben"
                              disabled={detail.disabled}
                              key={"FS_" & detail.key}
                              InputLabelProps={{ shrink: true }}
                              onChange={handleForainEingaben}
                              value={foreinLan ? foreinLan : detail.antwortFS}
                              multiline
                              minRows={4}
                              variant="outlined"
                            />

                            <div></div>

                            <Button
                              style={{ marginTop: "6px", marginRight: "10px" }}
                              variant="outlined"
                              key={detail.key + "_vorlesen_rechts"}
                            >
                              {"Vorlesen"}
                            </Button>

                            {detail.disabled !== true && (
                              <Button
                                className="mt-1 "
                                variant="outlined"
                                key={detail.key + "absenden"}
                                onClick={() => btnClick("absenden", detail.key, detail.kennung)}
                              >
                                <SendIcon size="sm" />
                                <span className="ml-1">Senden</span>
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                      //               </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}
      {/* original code ende */}
    </div>
  );
}
export default EcSpeech;
